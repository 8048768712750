import { Avatar, Button, Dropdown, Menu, Table, Typography } from "antd"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"
import { fetchUsers } from "../../../redux/Admin/Admin.actions"
import moment from "moment"
import TakingSessionModal from "../TakingSessionModal/TakingSessionModal.component"
import SetAsFreeAccountModal from "../SetAsFreeAccountModal/SetAsFreeAccountModal.component"
import * as _ from "lodash"
import "./UsersTable.styles.scss"
import ExtraTrialDaysModal from "../ExtraTrialDaysModal/ExtraTrialDaysModal.component"
import WipeAccountModal from "../WipeAccountModal/WipeAccountModal.component"
import SendWarningModal from "../SendWarningModal/SendWarningModal.component"
import RelatableLoader from "../../UI/RelatableLoader/RelatableLoader.component"
import ConfirmModal from "../../Database/BulkActionsDrawer/components/BulkActionsButtonsGroup/ConfirmModal/ConfirmModal.component"
import { createPartner } from "../../../redux/User/User.actions"

const { Text } = Typography

const UsersTable = () => {
  const users = useSelector((state) => state.AdminState.users)
  const user = useSelector((state) => state.UserState)
  const loading = useSelector((state) => state.AdminState.loading)
  const dispatch = useDispatch()

  const [selectedEmail, setSelectedEmail] = useState(null)
  const [takingSessionModalVisible, setTakingSessionModalVisible] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [searchInput, setSearchInput] = useState(null)
  const [extraTrialDaysModalVisible, setExtraTrialDaysModalVisible] = useState(false)
  const [createPartnerModalVisible, setCreatePartnerModalVisible] = useState(false)
  const [trialExpiredfilter, setSetTrialExpiredFilter] = useState(false)
  const [wipeAccountModalVisible, setWipeAccountModalVisible] = useState(false)
  const [sendWarningModalVisible, setSendWarningModalVisible] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)

  const [setAsFreeAccountModalVisible, setSetAsFreeAccountModalVisible] =
    useState(false)

  const bulkMenu = (
    <Menu>
      <Menu.Item
        key="2"
        onClick={() => {
          setWipeAccountModalVisible(true)
        }}
      >
        WIPE ACCOUNT
      </Menu.Item>
    </Menu>
  )

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setSetAsFreeAccountModalVisible(true)
        }}
      >
        SET AS FREE ACCOUNT
      </Menu.Item>
      {/*<Menu.Item*/}
      {/*  key="2"*/}
      {/*  onClick={() => {*/}
      {/*    setSendWarningModalVisible(true)*/}
      {/*  }}*/}
      {/*>*/}
      {/*  SEND DELETE WARNING*/}
      {/*</Menu.Item>*/}
      <Menu.Item
        key="2"
        onClick={() => {
          setWipeAccountModalVisible(true)
        }}
      >
        WIPE ACCOUNT
      </Menu.Item>
      <Menu.Item key="3" onClick={() => setExtraTrialDaysModalVisible(true)}>
        Add extra days
      </Menu.Item>
      {!selectedUser?.partner && (
        <Menu.Item key="4" onClick={() => setCreatePartnerModalVisible(true)}>
          Set as partner
        </Menu.Item>
      )}
    </Menu>
  )

  const columns = [
    {
      title: "",
      key: "avatar",
      width: "2.5%",
      render: (record) => <Avatar size={40} src={`${record.avatar}`} />,
    },
    {
      title: "Name",
      className: "name hoverable",
      key: "last_name",
      ellipsis: true,
      render: (record) => (
        <>
          <span className={"PeopleTable_FullNameWrap"}>
            <Text className={"PeopleTable_FullName"}>
              {record.first_name + " " + record.last_name}
            </Text>
          </span>
          <div className={"PeopleTable_OneLinerWrap"}>
            <Text className={"PeopleTable_OneLiner"}>{record.email}</Text>
          </div>
        </>
      ),
    },
    {
      width: "20%",
      title: "Last seen",
      className: "hoverable",
      key: "last_seen_at",
      sorter: (a, b) => new Date(a.last_seen_at) - new Date(b.last_seen_at),
      sortDirections: ["descend", "ascend"],
      defaultSortOrder: "descend",
      render: (record) => (
        <>
          <div className={"PeopleTable_RecencyContainer"}>
            {record.last_seen_at && (
              <Text className={"PeopleTable_RecencyText"}>
                {moment(record.last_seen_at)
                  .tz(user.time_zone)
                  .format("MMM Do, YYYY")}
              </Text>
            )}
          </div>
        </>
      ),
    },
    {
      title: "Is paid?",
      className: "name hoverable",
      key: "is_paid",
      render: (record) => (
        <>
          <div className={"PeopleTable_RecencyContainer"}>
            {record.is_paid && "yes"}
          </div>
        </>
      ),
    },
    {
      title: "Is partner?",
      className: "name hoverable",
      key: "is_partner",
      render: (record) => (
        <>
          <div className={"PeopleTable_RecencyContainer"}>
            {record.partner ? "yes" : "no"}
          </div>
        </>
      ),
    },
    {
      title: "Trial days left",
      className: "name hoverable trial_days_remaining",
      key: "trial_days_remaining",
      render: (record) => (
        <div className="PeopleTable_TrialDaysRemaining">
          <div className="PeopleTable_RecencyContainer">
            {record.trial_days_remaining}
          </div>
        </div>
      ),
    },
    {
      title: "Status",
      className: "name hoverable",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      sortDirections: ["descend", "ascend"],
      render: (record) => (
        <>
          <div className={"PeopleTable_RecencyContainer"}>{record.status}</div>
        </>
      ),
    },
    {
      title: "Impersonate",
      className: "name hoverable",
      key: "impersonate",
      render: (record) => (
        <>
          <Button
            onClick={() => {
              setTakingSessionModalVisible(true)
            }}
          >
            TAKE SESSION
          </Button>
        </>
      ),
    },
    {
      title: "Actions",
      className: "name hoverable",
      key: "actions",
      filters: [
        { text: "Trial expired and seen 30 days ago", value: "trial_expired" },
      ],
      onFilter: (value, record) => {
        if (value === "trial_expired") {
          setSetTrialExpiredFilter(!trialExpiredfilter)
          return (
            record.trial_days_remaining <= -30 &&
            record.is_paid === false &&
            record.status === "expired_trial" &&
            (record.last_seen_at === null ||
              moment(record.last_seen_at) < moment().subtract(30, "days"))
          )
        }
      },
      render: (record) => (
        <>
          <Dropdown
            disabled={selectedUsers.length > 0}
            overlay={menu}
            trigger={["click"]}
          >
            <Button
              className="ant-dropdown-link"
              type="link"
              onClick={(e) => e.preventDefault()}
            >
              Actions
            </Button>
          </Dropdown>
        </>
      ),
    },
  ]

  const handleTableChange = (pagination, filter, sorter) => {
    // const order = sorter.order === "ascend" ? "asc" : "desc"
    //
    // setSortField(sorter.columnKey)
    // setSortOrder(order)
    // setPage(pagination.current)

    dispatch(
      fetchUsers(pagination.current, 20, [], {
        trial_expired_only: trialExpiredfilter,
      })
    )
  }

  const searchUsers = (q) => {
    dispatch(fetchUsers("1", 20, q, { trial_expired_only: trialExpiredfilter }))
  }

  const [debouncedSearch] = useState(() => _.debounce(searchUsers, 500))

  useEffect(() => {
    dispatch(fetchUsers("1", 20, [], { trial_expired_only: trialExpiredfilter }))
    // eslint-disable-next-line
  }, [])

  const handleAddNewPartner = () => {
    dispatch(createPartner(selectedId))
  }

  return (
    <>
      <TakingSessionModal
        email={selectedEmail}
        visible={takingSessionModalVisible}
        onCancel={() => setTakingSessionModalVisible(false)}
      />
      <ExtraTrialDaysModal
        user_id={selectedId}
        visible={extraTrialDaysModalVisible}
        onCancel={() => setExtraTrialDaysModalVisible(false)}
      />
      <ConfirmModal
        onConfirm={handleAddNewPartner}
        visible={createPartnerModalVisible}
        onCancel={() => setCreatePartnerModalVisible(false)}
        title={"Create new Partner"}
        description={"Are you sure you want to create partner?"}
      />
      <SetAsFreeAccountModal
        id={selectedId}
        email={selectedEmail}
        visible={setAsFreeAccountModalVisible}
        onCancel={() => setSetAsFreeAccountModalVisible(false)}
      />
      <WipeAccountModal
        selected_users={
          selectedUsers.length > 0 ? selectedUsers : [selectedUser].filter((n) => n)
        }
        visible={wipeAccountModalVisible}
        onCancel={() => setWipeAccountModalVisible(false)}
        onWipeAccount={() => {
          setSelectedUsers([])
        }}
      />
      <SendWarningModal
        selected_users={
          selectedUsers.length > 0 ? selectedUsers : [selectedUser].filter((n) => n)
        }
        visible={sendWarningModalVisible}
        onCancel={() => setSendWarningModalVisible(false)}
      />
      <div className="SearchPeople SharedTableWrapper_Header_Search">
        <input
          value={searchInput}
          placeholder="Search..."
          onChange={(e) => {
            setSearchInput(e.target.value)
            if (e.target.value.length > 2 || e.target.value === "") {
              debouncedSearch(e.target.value)
            }
          }}
        />
        {selectedUsers.length > 0 && (
          <Dropdown overlay={bulkMenu} trigger={["click"]}>
            <Button
              type="link"
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              Actions
            </Button>
          </Dropdown>
        )}
      </div>
      <Table
        rowSelection={{
          selectedRowKeys: selectedUsers.map((user) => user.id),
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedUsers(selectedRows)
            console.log(
              `selectedRowKeys: ${selectedRowKeys}`,
              "selectedRows: ",
              selectedRows
            )
          },
          selections: true,
        }}
        onSelectAll={(selected, selectedRows, changeRows) => {
          setSelectedUsers(selectedRows)
        }}
        rowKey={"id"}
        tableLayout={"fixed"}
        className={"PeopleTable"}
        style={{ height: "auto", maxHeight: "80%" }}
        loading={{
          spinning: loading,
          indicator: <RelatableLoader quote={true} loading={loading} />,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setSelectedEmail(record.email)
              setSelectedId(record.id)
              setSelectedUser(record)
            },
          }
        }}
        scroll={{ x: true }}
        columns={columns}
        dataSource={users}
        onChange={handleTableChange}
      />
    </>
  )
}

export default UsersTable
