import React, { useEffect, useState } from "react"
import "./Dashboard.styles.scss"
import { useParams } from "react-router-dom"
import DashboardForm from "../../components/Dashboard/DashboardForm/DashboardForm.component"
import { fetchPeople, fetchSelectedPeople } from "../../redux/People/People.actions"
import { useDispatch, useSelector } from "react-redux"
import {
  fetchCompanies,
  fetchExpertises,
  fetchSpheres,
  fetchTopics,
} from "../../redux/Collections/Collections.actions"
import { approveTrigger } from "../../redux/Triggers/Triggers.actions"
import {
  fetchAllRemindersPeople,
  fetchEvents,
  fetchReminders,
} from "../../redux/Reminders/Reminders.actions"
import SyncingContactsModal from "../../components/Dashboard/SyncingContactsModal/SyncingContactsModal.component"
import { StringParam, useQueryParams } from "use-query-params"
import Reminders from "../../components/Dashboard/Reminders/Reminders.component"
import { ReactComponent as BellAndMan } from "./../../images/BellAndMan.svg"
import { Dropdown, Menu, Spin } from "antd"
import {
  setActiveTab,
  setContactDrawerVisible,
  setSplitMessageSenderVisible,
  setVisibleContactID,
  showNoAccountNotification,
} from "../../redux/App/App.actions"
import moment from "moment/moment"
import { fetchAccounts } from "../../redux/Accounts/Accounts.actions"
import Icon, { DownOutlined } from "@ant-design/icons"
import EventList from "../../components/Dashboard/EventList/EventList.component"
import OnboardingScreens from "../../components/Dashboard/OnboardingScreens/OnboardingScreens.component"
import StayInTouchReminder from "../../components/Dashboard/StayInTouchReminder/StayInTouchReminder.component"
import FocusMode from "../../components/Dashboard/FocusMode/FocusMode.component"
import FocusModeTab from "../../components/Dashboard/FocusModeTab/FocusModeTab.component"
import { getUserDetails, updateShowFocusMode } from "../../redux/User/User.actions"
import { getLinkedinStatus } from "../../components/Account/Account.actions"
import LinkedInIcon from "../../components/Icons/LinkedIn.icons"
import WelcomeBar from "../../components/Dashboard/WelcomeBar/WelcomeBar"
import RelatableLoader from "../../components/UI/RelatableLoader/RelatableLoader.component"
import history from "../../history"

const Dashboard = ({ show_person = false, approve_trigger = false }) => {
  const dispatch = useDispatch()

  const people = useSelector((state) => state.PeopleState.people)
  const isUserActive = useSelector((state) => state.AccountsState.is_user_active)

  const user = useSelector((state) => state.UserState)
  const reminders = useSelector((state) => state.RemindersState.reminders)
  const loading_more = useSelector((state) => state.RemindersState.loading_more)
  const fetchingPerson = useSelector((state) => state.PeopleState.fetching)
  const fetchingEvents = useSelector((state) => state.RemindersState.fetching)
  const spheres = useSelector((state) => state.CollectionsState.spheres)
  const companies = useSelector((state) => state.CollectionsState.companies)
  const triggers = useSelector((state) => state.RemindersState.triggers)
  const participants = useSelector((state) => state.ParticipantsState.participants)
  const accounts = useSelector((state) => state.AccountsState.accounts)

  const [showSyncingContactsModal, setShowSyncingContactsModal] = useState(false)
  const [remindersCount, setRemindersCount] = useState(
    localStorage.reminders_count || 10
  )
  const [pageEvent, setPageEvent] = useState(1)
  const [linkedinStatus, setLinkedinStatus] = useState(null)
  const [showFocusMode, setShowFocusMode] = useState(false)
  const [queryParams] = useQueryParams({
    show_syncing_contacts: StringParam,
    welcome_step: StringParam,
    show_stay_in_touch_reminder: StringParam,
    onboarding_step: StringParam,
  })
  const params = useParams()

  const setViewPerson = (personId) => {
    dispatch(setActiveTab(null))
    dispatch(setContactDrawerVisible(true))
    dispatch(setVisibleContactID(personId))
  }

  const handleCheckStatus = () => {
    getLinkedinStatus((response) => {
      setLinkedinStatus(response.status)
    })
  }

  useEffect(() => {
    if (!people || !people.length) {
      dispatch(fetchPeople(1, 20, null, null, "last_sent", "desc"))
    }
    dispatch(fetchTopics())
    dispatch(fetchExpertises())

    if (!spheres) {
      dispatch(fetchSpheres())
    }
    if (!companies) {
      dispatch(fetchCompanies())
    }
    dispatch(fetchAccounts())
    dispatch(getUserDetails())
    dispatch(fetchReminders(remindersCount))
    handleCheckStatus()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user.show_focus_mode) {
      setShowFocusMode(true)
      dispatch(updateShowFocusMode(false))
    }
  }, [user.show_focus_mode])

  useEffect(() => {
    if (queryParams.show_syncing_contacts === "true") {
      setShowSyncingContactsModal(true)
    }
    if (show_person && params.id) {
      setViewPerson(parseInt(params.id))
    }
    if (approve_trigger && params.id) {
      dispatch(approveTrigger(parseInt(params.id)))
    }
    // eslint-disable-next-line
  }, [queryParams])

  useEffect(() => {
    //Run after contact editing
    if (!fetchingPerson) {
      dispatch(fetchEvents(1, pageEvent * 3))
      dispatch(fetchReminders())
    }
    // eslint-disable-next-line
  }, [fetchingPerson])

  useEffect(() => {
    dispatch(fetchEvents(pageEvent, 3))
    // eslint-disable-next-line
  }, [])

  const handleChangeRemindersCount = (count) => {
    localStorage.setItem("reminders_count", count)
    setRemindersCount(count)
  }
  const handleOnLoadMoreEvents = (reset = false) => {
    if (reset) {
      setPageEvent(1)
    } else {
      setPageEvent(pageEvent + 1)
      dispatch(fetchEvents(pageEvent + 1, 3))
    }
  }

  const renderBanner = () => {
    let alert = null

    if (
      accounts?.filter((acc) => !!acc.nylas_id && acc.state !== "running").length > 0
    ) {
      alert = "account_warning"
    } else if (linkedinStatus === "missing_token") {
      alert = "missing_token"
    } else if (!isUserActive || !!queryParams.show_stay_in_touch_reminder) {
      alert = "stay_in_touch"
    } else if (
      reminders?.filter((reminder) => reminder.type === "from_trigger").length > 0 ||
      triggers?.length > 0 ||
      participants?.length > 0
    ) {
      alert = "focus_mode"
    }

    switch (alert) {
      case "missing_token":
        return (
          <div className="Dashboard_Header_AccountWarning Linkedin">
            <Icon component={LinkedInIcon} />
            {`Whoops! It appears that you are logged out of your LinkedIn account.`}
            <a href="/account/settings?section=Accounts&linkedin_open=true">
              Click here to fix it
            </a>
          </div>
        )
      case "account_warning":
        return (
          <div className="Dashboard_Header_AccountWarning">
            {`Hey ${
              user?.first_name ? ` ${user.first_name}` : ""
            }! Looks like your account ${
              accounts?.filter((acc) => !!acc.nylas_id && acc.state !== "running")[0]
                ?.email
            } isn't connected right now.`}{" "}
            <a href="/account/settings?section=Accounts">Click here to fix it</a>
          </div>
        )
      case "focus_mode":
        return <FocusModeTab setShowFocusMode={setShowFocusMode} />
      case "stay_in_touch":
        return (
          <StayInTouchReminder
            showStayInTouchReminder={!!queryParams.show_stay_in_touch_reminder}
          />
        )
      default:
        return null
    }
  }

  const handleClickSplitMessageButton = () => {
    const runningAccounts = accounts.filter((a) => a.state === "running")
    if (runningAccounts.length > 0) {
      dispatch(fetchAllRemindersPeople())
    } else {
      dispatch(showNoAccountNotification())
    }
  }

  return (
    <>
      <div className="Dashboard">
        <div className="Dashboard_Header">
          <h1>{`Happy ${moment().tz(user.time_zone).format("dddd")}${
            user?.first_name ? ` ${user.first_name}` : ""
          }!`}</h1>
          <WelcomeBar />
        </div>
        {queryParams.onboarding_step !== undefined && (
          <OnboardingScreens defaultStep={queryParams.onboarding_step} />
        )}
        {showFocusMode && <FocusMode onExit={() => setShowFocusMode(false)} />}
        {renderBanner()}

        <h3 className="Dashboard_Cards_Header">Relatable recommendations</h3>
        <EventList
          onLoadMore={handleOnLoadMoreEvents}
          first_page_loading={pageEvent === 1 && fetchingEvents}
          fetching={
            (loading_more && pageEvent === 1) || fetchingPerson || fetchingEvents
          }
          loading_more={loading_more}
          page={pageEvent}
        />
        <DashboardForm />
        <div className="Dashboard_Cards">
          <div className="Dashboard_Cards_HeaderContainer">
            <h3 className="Dashboard_Cards_Header">Reminders</h3>
            <div className="Dashboard_Cards_Header_Buttons">
              <button
                className="Dashboard_Cards_Header_SendMessageButton"
                onClick={() => {
                  handleClickSplitMessageButton()
                }}
              >
                Split Message Everyone
              </button>
              <Dropdown
                overlay={
                  <Menu>
                    {[5, 10, 20, 50, 100].map((n, i) => {
                      return (
                        <Menu.Item
                          key={i}
                          onClick={() => {
                            handleChangeRemindersCount(n)
                          }}
                        >
                          {n}
                        </Menu.Item>
                      )
                    })}
                  </Menu>
                }
                trigger={["click"]}
              >
                <button
                  className="Dashboard_Cards_Header_ShowMoreButton"
                  onClick={(e) => e.preventDefault()}
                >
                  Show {remindersCount} <DownOutlined />
                </button>
              </Dropdown>
            </div>
          </div>

          {reminders?.length > 0 ? (
            <Reminders remindersCount={remindersCount} reminders={reminders} />
          ) : fetchingPerson ? (
            <div className="Dashboard_Cards_Loading">
              <RelatableLoader />
            </div>
          ) : (
            <div className="Dashboard_Cards_Empty">
              <BellAndMan />
              <div>
                <h3>Free and clear!</h3>
                <span>
                  Reminders you approve from the{" "}
                  <b>People you may want to re-engage with</b> section will show up
                  here, as well as any tasks you’ve created for specific contacts.
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
      {showSyncingContactsModal && (
        <SyncingContactsModal
          onClose={() => setShowSyncingContactsModal(false)}
          visible={showSyncingContactsModal}
        />
      )}
    </>
  )
}

export default Dashboard
