import {
  ADD_REMINDER,
  ADD_REMINDER_FAIL,
  ADD_REMINDER_SUCCESS,
  CLEAR_REDUX_REMINDERS_STATE,
  COMPLETE_REMINDER,
  COMPLETE_REMINDER_FAIL,
  COMPLETE_REMINDER_SUCCESS,
  DELETE_REMINDER,
  DELETE_REMINDER_FAIL,
  DELETE_REMINDER_SUCCESS,
  FETCH_ALL_REMINDERS_PEOPLE,
  FETCH_ALL_REMINDERS_PEOPLE_FAIL,
  FETCH_ALL_REMINDERS_PEOPLE_SUCCESS,
  FETCH_EVENTS,
  FETCH_EVENTS_FAIL,
  FETCH_EVENTS_SUCCESS,
  FETCH_REMINDERS,
  FETCH_REMINDERS_FAIL,
  FETCH_REMINDERS_SUCCESS,
  IGNORE_SPECIAL_DATE,
  IGNORE_SPECIAL_DATE_FAIL,
  IGNORE_SPECIAL_DATE_SUCCESS,
  SET_INITIAL_REDUX_REMINDERS_STATE,
  UPDATE_REMINDER,
  UPDATE_REMINDER_FAIL,
  UPDATE_REMINDER_SUCCESS,
} from "./Reminders.types"

export const addReminder = (values) => {
  return {
    type: ADD_REMINDER,
    name: values.name,
    details: values.details,
    due_at: values.due_at,
    people: values.people,
    topics: values.topics,
    expertises: values.expertises,
  }
}

export const addReminderSuccess = (reminder) => {
  return {
    type: ADD_REMINDER_SUCCESS,
    reminder: reminder,
  }
}

export const addReminderFail = (error) => {
  return {
    type: ADD_REMINDER_FAIL,
    error: error,
  }
}

export const fetchReminders = () => {
  return {
    type: FETCH_REMINDERS,
  }
}

export const fetchRemindersSuccess = (reminders) => {
  return {
    type: FETCH_REMINDERS_SUCCESS,
    reminders: reminders,
  }
}

export const fetchRemindersFail = (error) => {
  return {
    type: FETCH_REMINDERS_FAIL,
    error: error,
  }
}

export const completeReminder = (id, visibleContactID) => {
  return {
    type: COMPLETE_REMINDER,
    id: id,
    visibleContactID: visibleContactID,
  }
}

export const completeReminderSuccess = (reminder) => {
  return {
    type: COMPLETE_REMINDER_SUCCESS,
    reminder: reminder,
  }
}

export const completeReminderFail = (error) => {
  return {
    type: COMPLETE_REMINDER_FAIL,
    error: error,
  }
}

export const deleteReminder = (id) => {
  return {
    type: DELETE_REMINDER,
    id: id,
  }
}

export const deleteReminderSuccess = (reminder) => {
  return {
    type: DELETE_REMINDER_SUCCESS,
    reminder: reminder,
  }
}

export const deleteReminderFail = (error) => {
  return {
    type: DELETE_REMINDER_FAIL,
    error: error,
  }
}

export const fetchEvents = (page, count) => {
  return {
    type: FETCH_EVENTS,
    page: page,
    count: count,
  }
}

export const fetchEventsSuccess = (
  special_dates,
  triggers,
  job_change,
  page,
  count
) => {
  return {
    type: FETCH_EVENTS_SUCCESS,
    special_dates: special_dates,
    triggers: triggers,
    job_change: job_change,
    page: page,
    count: count,
  }
}

export const fetchEventsFail = (error) => {
  return {
    type: FETCH_EVENTS_FAIL,
    error: error,
  }
}

export const ignoreSpecialDate = (
  special_date_id,
  person_id,
  page,
  count,
  callback
) => {
  return {
    type: IGNORE_SPECIAL_DATE,
    special_date_id: special_date_id,
    person_id: person_id,
    page: page,
    count: count,
    callback: callback,
  }
}

export const ignoreSpecialDateSuccess = () => {
  return {
    type: IGNORE_SPECIAL_DATE_SUCCESS,
  }
}

export const ignoreSpecialDateFail = (error) => {
  return {
    type: IGNORE_SPECIAL_DATE_FAIL,
    error: error,
  }
}

export const clearReduxRemindersState = () => {
  return {
    type: CLEAR_REDUX_REMINDERS_STATE,
  }
}

export const setInitialReduxRemindersState = () => {
  return {
    type: SET_INITIAL_REDUX_REMINDERS_STATE,
  }
}

export const updateReminder = (id, values) => {
  return {
    type: UPDATE_REMINDER,
    id: id,
    name: values.name,
    details: values.details,
    due_at: values.due_at,
    people: values.people,
    topics: values.topics,
    expertises: values.expertises,
  }
}

export const updateReminderSuccess = (reminder) => {
  return {
    type: UPDATE_REMINDER_SUCCESS,
    reminder: reminder,
  }
}

export const updateReminderFail = (error) => {
  return {
    type: UPDATE_REMINDER_FAIL,
    error: error,
  }
}

export const fetchAllRemindersPeople = () => {
  return {
    type: FETCH_ALL_REMINDERS_PEOPLE,
  }
}

export const fetchAllRemindersPeopleSuccess = () => {
  return {
    type: FETCH_ALL_REMINDERS_PEOPLE_SUCCESS,
  }
}

export const fetchAllRemindersPeopleFail = () => {
  return {
    type: FETCH_ALL_REMINDERS_PEOPLE_FAIL,
  }
}
